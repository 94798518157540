const Theme: any = {};

Theme.colors = {
  brand: '#ffa510',
  accent1: '#f24f09',
  accent2: '#15D6A9',
  black: '#201807',
  white: '#ffffff',
  orange1200: '#f58300',
  orange200: '#fae6d1',
  orange150: '#faf0ed',
  orange100: '#fdf9f3',
  orange50: '#fffbfa',
  grey500: '#b0b0b0',
};

Theme.typography = {
  sansSerifFontFamily: '"Saira", sans-serif',
  monospaceFontFamily: '"Sono", monospace',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: Theme.colors.black,
};

export { Theme };

export type ThemeType = typeof Theme;
